import React, { useRef,useEffect } from 'react';
import  { Link } from "react-router-dom";
import logo from "../src/images/kidzilogo.png";
import {TweenMax,Power3} from 'gsap';

const Navbar = () => {
  let  logoItem = useRef(null);

  useEffect(()=>{
console.log(logoItem);
TweenMax.to(
  logoItem,
  .8,{
    opacity:1,
    y:-5,
    ease:Power3.easeIn
  }
)
  },[])
    return (
      <>



     <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-12 mx-auto">
            <div className="sticky-top">
     <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <a className="navbar-brand" href="#"><img 
    ref={el => {logoItem=el}}
    src={logo} alt="kidzibooks"></img></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
      
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/">Home</Link>
        </li>
        
           


        <li className="nav-item">
          <Link className="nav-link" to="/Worksheets" style={{textDecoration: "none" }}>Worksheets</Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/Olympiads" style={{textDecoration: "none" }}>Olympiads</Link>
        </li>
       
        <li className="nav-item">
          <Link className="nav-link" to="/GetFranchise" style={{textDecoration: "none" }}>Get Franchise</Link>
        </li>
            
        <li className="nav-item">
          <Link className="nav-link" to="/cbsesyllabus" style={{textDecoration: "none" }}>CBSE Syllabus</Link>
        </li>

        
      </ul>
      
    </div>
  </div>
</nav>
</div>


</div>
        </div>

     </div>

  </>
  
     
    );
  }



export default Navbar;