import React from 'react';
const Cbsesyllabus = () => {
    return (
      <>

<h1>Cbse Syllabus</h1>
      </>

);
}

export default Cbsesyllabus;
