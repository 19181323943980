import React from 'react';
const Olympiads = () => {
    return (
      <>

<h1>Olympiads</h1>



      </>

);
}

export default Olympiads;
