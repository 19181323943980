import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './Home';
import Olympiads from './Olympiads';
import Getfranchise from './Getfranchise';
import Worksheet from './Worksheet';
import Cbsesyllabus from './Cbsesyllabus';
import Navbar from './Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Footer from './Footer';


const App = () => {
  return (
    <>
    <Router>
    <Navbar />
    <Routes>
            <Route path="/" element={ <Home />} />
            <Route path="/Worksheets" element={<Worksheet />} />
        <Route path="/Olympiads" element={<Olympiads />} />
        <Route path="/CbseSyllabus" element={<Cbsesyllabus />} />
        <Route path="/GetFranchise" element={<Getfranchise />} />
        
        
       
    </Routes>
</Router>
<Footer />


  </>
  
  );
}

export default App;
