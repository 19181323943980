// import {motion} from 'framer-motion';
import {motion} from 'framer-motion/dist/framer-motion'
import * as React from 'react';
import './Ethics.css';
import blockright from "../src/images/baby.png";
 const Ethics = () => {
    return (
      <>
      <section id="header8" style={{background:"dodgerblue",color:"white"}}>
        <div className="container-fluid nav_bg">
        <div className='row pdn'>
            <div className="col-12 mx-auto" >
                <center><h1>Ethics</h1></center>
              <div className="row jy">
             <br></br>
              <div className="col-md-4 ps-5 pt-lg-0 order-1 order-lg-2 d-flex justify-content-center flex-column">
             
                 <h3>Activity Based Learning</h3>
                 <p>The Kidzibooks Book Sets provide stimulating learning experiences to the little minds with activity based learning to challenge them and respond to the individual’s unique characteristics and learning styles, helping them reach their full potential.</p>
              </div>

        <div className='col-lg-4 ps-5 order-2 order-lg-2 justify-content-center header-img1'>


        </div>

        <div className="col-md-4 ps-5 pt-lg-0 order-3 order-lg-3 d-flex justify-content-center flex-column">
        <h3>	
Engaging Illustrations</h3>
        <p>Keeping in mind the tender age of our tiny tots and their attraction to images and colours, the Kidzibooks Books Sets have beautiful and colourful illustrations to keep the kids interested and engaged in the books while learning important concepts!</p>

        </div>

    </div>

    <div className="row jy">
             <br></br>
              <div className="col-md-4 ps-5 pt-lg-0 order-1 order-lg-2 d-flex justify-content-center flex-column">
             
          <h3>Revision of Concepts</h3>           
        <p>It is very important for the little ones to continuously revise the concepts previously learning and then grasp new things. Kidzibooks Book Sets are designed keeping this need of the kids in mind so that they can easily relate to the concepts previously studied and build further knowledge on them.</p>
        </div>

        <motion.div className='col-lg-4 ps-5 order-2 order-lg-2 justify-content-center header-img1'
          whileHover={{scale:1.2,rotat:90 }}
          whileTap={{
            scale:0.8,
            rotate:-90,
            borderRadius:"100%"

            
          }}
        
        >

        <img src={blockright} className="img-fluid animated" alt="home img"/>
        </motion.div>

        <div className="col-md-4 ps-5 pt-lg-0 order-3 order-lg-3 d-flex justify-content-center flex-column">
        <h3>	
Descriptive Walkthroughs</h3>
        <p>Our students get benefits from our engaging activities, intrinsically interesting materials, and age-appropriate development activities that enhance their creativity, thinking skills, and language abilities. Each book of the Kidzibooks Book Sets has a walkthrough to describe your learning journey in the book.</p>

        </div>

    </div>


    <div className="row jy">
             <br></br>
              <div className="col-md-4 ps-5 pt-lg-0 order-1 order-lg-2 d-flex justify-content-center flex-column">
             
          <h3>	
Different Approach</h3>           
        <p>With our extraordinary methodology, the kids are urged to learn by contacting, feeling, and getting things done. They gain an extremely substantial comprehension of the materials which instills possession and administration.</p>
        </div>

        <div className='col-lg-4 ps-5 order-2 order-lg-2 justify-content-center header-img1'>


        </div>

        <div className="col-md-4 ps-5 pt-lg-0 order-3 order-lg-3 d-flex justify-content-center flex-column">
        <h3>Well Qualified Writers</h3>
        <p>Our writers are well qualified and equipped with regular training, workshops, and assessments which make them efficient in creating engaging content and sprucing up to provide the required acumen and resources.</p>

        </div>

    </div>




  
  </div>
   </div>
</div>
</section>
        </>

   
        );
      }
      
      export default Ethics;
      

 