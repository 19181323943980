import React from 'react';
// import {motion} from "framer-motion";
import {motion} from 'framer-motion/dist/framer-motion'
import './Banner.css';
const Banner = () => {
 
  return (
    <>
    <motion.div className="container-fluid nav_bg"
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      default: {
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01]
      },
      scale: {
        type: "spring",
        damping: 5,
        stiffness: 100,
        restDelta: 0.001
      }
    }}
   
    >
        <div className='row'>
            <div className="col-12 mx-auto">

              <div className="row">
             
<div className='banner' >
              <div className="banner__content">
    <div className="container">
        <div className="banner__text">
       
       
       
        </div>
    </div>
    </div>
</div>
</div>                      

             


  </div>
   </div>
</motion.div>
</>

   
  );
}

export default Banner;





