import React from 'react';
// import {motion} from "framer-motion";
import {motion} from 'framer-motion/dist/framer-motion'
import sheet from "../src/images/colouring.png";
import sheet1 from "../src/images/pattern.png";
import sheet2 from "../src/images/alphabet.png";
import sheet3 from "../src/images/storypic.png";

import sheet4 from "../src/images/worksheet.png";

import sheet5 from "../src/images/pattern.png";



const Ourbooks = () => {
  return (
    
    <>

<div className='my-5'>
    <h1 className='text-center'>Our Books</h1>
   </div>
    <section id="header3" className="d-flex align-items-center my-3">
    <motion.div className="container-fluid nav_bg"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row'>
              <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
              <img src={sheet} alt="kidzi"></img>
              </div>

              <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
              <img src={sheet1} alt="kidzi"></img>
            </div>


            <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
            <img src={sheet2} alt="kidzi"></img>
            </div>

            <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
            <img src={sheet3} alt="kidzi"></img>
            </div>

            <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
            <img src={sheet4} alt="kidzi"></img>
            </div>

            <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
            <img src={sheet5} alt="kidzi"></img>
            </div>

            

              </div>
              </div>

              </div>
              </motion.div>
             
          

              </section>

             

</>

   
  );
}

export default Ourbooks;
